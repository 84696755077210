@import '../../assets/scss/mixins.scss';

.Header {
  position: relative;
  width: 100%;
  z-index: 11;

  .inner {
    width: 100%;
    height: 70px;
    // position: fixed;
    // top: 0;
    // left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;
    background: var(--color-white);

    @include desktop {
      padding: 0 40px 0 60px;
      height: 100px;
    }
  }
}
