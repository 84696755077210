//BREAKPOINTS
@mixin mobile {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1000px) {
    @content;
  }
}
