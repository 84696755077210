@import '../../assets/scss/mixins.scss';

.Home {
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
  overflow: hidden;

  @include desktop {
    min-height: calc(100vh - 100px);
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 20px 40px;

    @include desktop {
      padding: 0 100px 200px;
      align-items: flex-end;
    }
  }
}
