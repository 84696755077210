@import '../../assets/scss/mixins.scss';

.socials {
  width: 100%;
  position: absolute;
  top: calc(72vh - 110px);
  left: 50%;
  transform: translateX(-50%);

  @include mobile {
    display: none;
  }

  a {
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
  svg {
    width: 35px;
    height: 35px;
    transition: fill 0.3s ease;

    &:hover {
      fill: var(--color-primary);
    }
  }
}
