@import '../../assets/scss/mixins.scss';

.Gallery {
  position: relative;
  min-height: calc(100vh - 160px);

  .nav {
    .navList {
      padding: 20px 0;
      list-style: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .navLink {
        font-size: 20px;
        position: relative;
        transition: all 0.3s ease;
        margin-bottom: 15px;

        &:not(:last-child) {
          @include desktop {
            margin-right: 30px;
            margin-bottom: 0;
          }
        }

        .navLinkInner {
          // border: 1px solid var(--color-black);
          padding: 5px 10px;
          transition: all 0.3s ease;

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }
}
