@import '../../assets/scss/mixins.scss';

.menuIcon {
  cursor: pointer;
  backface-visibility: hidden;
  // width: 40px;
  // height: 40px;
  // background: var(--color-white);
  // border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;

  @include desktop {
    // width: 60px;
    // height: 60px;
    // margin-top: 0;
  }

  .line {
    width: 27px;
    height: 2px;
    background: var(--color-black);
    transition: all 0.3s ease;
    display: block;
    backface-visibility: hidden;

    @include desktop {
      width: 35px;
    }

    &:not(:last-child) {
      margin-bottom: 4px;

      @include desktop {
        margin-bottom: 5px;
      }
    }
  }

  &:hover {
    .line:first-child {
      transform: translateY(-2px);
    }

    .line:last-child {
      transform: translateY(2px);
    }
  }

  &.openMenu {
    .line:nth-child(2) {
      opacity: 0;
    }

    .line:first-child {
      transform: rotate(45deg) translateY(6px);
      margin-bottom: 0;
      transition-delay: 0.1s;

      @include desktop {
        transform: rotate(45deg) translateY(7px);
      }
    }

    .line:last-child {
      transform: rotate(-45deg) translateY(-6px);
      transition-delay: 0.1s;

      @include desktop {
        transform: rotate(-45deg) translateY(-7px);
      }
    }

    .text {
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  .text {
    font-size: 9px;
    text-transform: uppercase;
    display: block;
    margin-top: 4px;

    @include desktop {
      font-size: 11px;
      margin-top: 8px;
    }
  }
}
