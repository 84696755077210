@import '../../assets/scss/mixins.scss';

.About {
  header {
    width: 100%;
    height: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
      height: 580px;
    }
    .headerImage {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    // video {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   display: block;
    //   object-fit: cover;
    // }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }

    h1 {
      position: relative;
      font-size: 30px;
      color: var(--color-white);
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;

      @include desktop {
        font-size: 50px;
      }
    }
  }

  main {
    section {
      padding: 50px 30px 0;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;

      @include tablet {
        text-align: center;
      }

      @include desktop {
        padding: 100px 0 0;
      }

      &:last-child {
        padding: 50px 0;

        @include desktop {
          padding: 80px 0;
        }
      }

      h2 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 800;
        color: var(--color-primary);
        letter-spacing: 1.3px;
        margin-bottom: 10px;

        @include desktop {
          font-size: 18px;
        }
      }

      h3 {
        font-size: 24px;
        font-family: var(--font-secondary);
        font-weight: 400;
        line-height: 38px;
      }

      p {
        font-size: 14px;
        line-height: 22px;

        @include desktop {
          font-size: 18px;
          line-height: 28px;
        }
      }

      img {
        display: flex;
      }
    }
    .infoFirst {
      h3 {
        font-size: 18px;
        line-height: 26px;
        margin: 30px 0;

        @include desktop {
          margin: 45px 0;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .images {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;

      img {
        object-fit: cover;
        width: 30%;
        height: 300px;

        @include mobile {
          &:first-child,
          &:last-child {
            display: none;
          }
        }

        &:nth-child(2) {
          width: 100%;
          height: 300px;
          object-position: 100% 15%;
          display: block;

          @include desktop {
            width: 40%;
            height: 400px;
          }
        }
      }
    }

    .infoSecond {
      width: 100%;
      max-width: 900px;
    }

    .imageLarge {
      width: 100%;
      max-width: 100%;

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;

        @include desktop {
          height: 600px;
        }
      }
    }

    .partners {
      @include desktop {
        max-width: 1000px;
      }
    }

    .price {
      margin: 80px auto 80px;
      padding: 50px 20px !important;
      border: 1px solid #000;
      text-align: center;
      width: 80%;

      @include desktop {
        width: 100%;
        margin: 100px auto 100px;
      }

      h2 {
        margin-bottom: 20px;
      }

      p {
        @include desktop {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .btn {
        background: var(--color-black);
        color: var(--color-white);
        padding: 10px 30px;
        margin-top: 25px;
        position: relative;
        display: inline-block;
        transition: all 0.3s ease;

        &:hover {
          background: var(--color-primary);
        }
      }
    }
  }
}
