@import '../../assets/scss/mixins.scss';

.logo {
  width: 60px;
  position: relative;

  @include desktop {
    width: 70px;
  }
}

.logo img {
  width: 100%;
  height: 100%;
}
