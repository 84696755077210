@import '../../assets/scss/mixins.scss';

.success {
  width: 100vw;
  min-height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @include desktop {
    min-height: calc(100vh - 160px);
  }

  .checkmark {
    width: 100px;
    margin-bottom: 20px;
    margin-top: -120px;
  }

  p {
    margin-top: 10px;
  }

  .btn {
    background: var(--color-primary);
    color: var(--color-white);
    padding: 10px 30px;
    margin-top: 50px;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease;
    text-transform: uppercase;

    &:hover {
      background: var(--color-black);
    }
  }
}
