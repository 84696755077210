@import '../../../assets/scss/mixins.scss';

.nav {
  .navList {
    list-style: none;

    @include tablet {
      display: flex;
      transform: translateY(-110px);
    }

    .navLink {
      position: relative;
      color: var(--color-text);
      font-family: var(--font-secondary);
      font-size: 40px;
      width: max-content;
      transition: transform 0.2s ease;
      margin-bottom: 30px;

      @include tablet {
        font-size: 26px;
        margin-bottom: 0;
      }

      @include desktop {
        font-size: 35px;
      }

      &:not(:last-child) {
        margin-right: 35px;

        @include desktop {
          margin-right: 70px;
        }
      }

      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}
