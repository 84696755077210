@import '../../assets/scss/mixins.scss';

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: var(--color-white);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out 0.1s;

  @include desktop {
    transform: translateX(100%);
  }

  &.open {
    transform: translate(0);
  }

  .wrapper {
    width: 750px;
    display: flex;
    justify-content: center;

    @include tablet {
      flex-direction: column;
    }

    @include desktop {
      justify-content: space-between;
    }

    & > * {
      @include tablet {
        display: flex;
        justify-content: center;
      }
    }
  }
}
