@import '../../../assets/scss/mixins.scss';

.contactInfo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 72vh;

  @include mobile {
    display: none;
  }

  @include tablet {
    padding: 30px 0;
  }

  @include desktop {
    padding: 55px 0;
  }

  &::before {
    position: absolute;
    content: '';
    width: 115%;
    height: 1px;
    background: var(--color-secondary);
    left: 50%;
    transform: translateX(-50%);
  }

  &::before {
    top: 0;
  }

  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between !important;
    padding-left: 0;
    max-width: 550px;
  }

  @include desktop {
    max-width: 700px;
  }

  .infoBlock {
    width: max-content;
    position: relative;
    height: 100%;

    .infoBlockHeader {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      position: relative;
      font-size: 13px;
    }

    svg {
      fill: var(--color-primary);
      position: absolute;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      opacity: 0.15;
      z-index: -1;
      top: -30px;
      left: -20px;
    }

    span {
      color: var(--color-grey);
      display: block;
      font-size: 13px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
