footer {
  width: 100%;
  height: 80px;
  background: var(--color-black);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  //   @include tablet {
  //     height: $height-bar-tablet;
  //   }
  //   @include desktop {
  //     height: $height-bar-desktop;
  //   }

  .socialLinks {
    margin-bottom: 10px;

    .socialList {
      display: flex;
      list-style: none;

      .socialListItem {
        position: relative;
        color: var(--color-white);

        &:nth-child(2) {
          &:before,
          &:after {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            background: var(--color-primary);
            border-radius: 50%;
            top: 50%;
          }

          &:before {
            right: -17.5px;
          }

          &:after {
            left: -17.5px;
          }
        }

        &:not(:last-child) {
          margin-right: 30px;
        }
        a {
          font-size: 12px;
        }
      }
    }
  }

  .copyright {
    p {
      color: var(--color-white);
      font-size: 10px;
      text-align: center;
    }
  }
}
