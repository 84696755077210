@import '../../assets/scss/mixins.scss';

.Contact {
  width: 100%;
  min-height: calc(100vh - 160px);
  padding-top: 60px;
  position: relative;

  @include desktop {
    padding-top: 30px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }
  }

  .content {
    width: 100%;

    @include desktop {
      width: calc(50% - 50px);
    }

    .contentTitle {
      font-weight: 300;
      position: relative;
      display: inline-block;
      margin-bottom: 50px;
      font-family: var(--font-secondary);
      font-size: 35px;

      @include tablet {
        font-size: 45px;
      }

      @include desktop {
        font-size: 55px;
        line-height: 62px;
      }
    }

    .paragraph {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 60px;
    }
  }

  .formWrapper {
    background: var(--color-white);
    margin-top: 80px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 30px 25px;
    position: relative;

    @include desktop {
      margin-top: 50px;
      padding: 50px 40px;
      width: calc(50% - 50px);
      max-width: 100%;
    }

    @include desktop {
      &::after {
        position: absolute;
        content: '';
        right: -40px;
        top: -35px;
        width: 520px;
        height: 510px;
        background: var(--color-secondary);
        z-index: -1;
        opacity: 0.3;
      }
    }

    form {
      input,
      textarea {
        width: 100%;
        outline: none;
        color: var(--color-black);
        font-size: 16px;
        font-family: var(--font-primary);
        border: none;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--color-black);
        background-color: transparent;
        border-radius: 0;

        &::placeholder {
          color: var(--color-black);
        }
      }

      input {
        height: 40px;
        padding-left: 15px;

        &[type='submit'] {
          border: none;
          background: var(--color-primary);
          color: var(--color-white);
          font-style: normal;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 700;
          transition: all 0.4s ease;
          padding: 0;
          height: 50px;
          cursor: pointer;
          margin-bottom: 0;

          &:hover {
            background: var(--color-black);
          }
        }
      }

      textarea {
        padding: 0 15px;
        resize: none;
        min-height: 120px;
        display: flex;
        align-items: center;
      }
    }
  }

  .contact {
    width: 100%;
    position: relative;
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      width: 2px;
      background: var(--color-primary);
    }

    h3 {
      margin-bottom: 15px;
      font-size: 20px;
      // color: var(--color-white);
    }

    .contactInfo {
      & > * {
        font-size: 18px;
        // color: var(--color-white);

        line-height: 25px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .ContactImages {
    position: relative;
    z-index: 999;
    width: 100%;
    display: flex;

    .ContactImage {
      width: 50%;
      height: 650px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        display: flex;
      }
    }
  }
}
