@import '../../assets/scss/mixins.scss';

.partners {
  h2 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    color: var(--color-primary);
    letter-spacing: 1.3px;
    text-align: center;
    margin-bottom: 20px;
  }

  .partnerLogos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .logoWrapper {
      width: calc((100% / 3) - 10px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      @include tablet {
        margin-top: 60px;
        width: calc((100% / 4) - 10px);
      }
    }

    img {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;

      @include tablet {
        width: 100px;
      }

      @include desktop {
        width: 130px;
      }
    }
  }
}
