@import '../../assets/scss/mixins.scss';

.GalleryGrid {
  padding: 30px 0;
  line-height: 0;
  column-count: 1;
  column-gap: 10px;

  @include tablet {
    column-count: 2;
  }

  @include desktop {
    padding: 80px 0;
    column-gap: 20px;
    column-count: 2;
  }

  .gridItem {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 10px;

    @include desktop {
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      display: flex;
      object-position: cover;
    }
  }
}
