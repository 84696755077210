@import '../../assets/scss/mixins.scss';

.Wedding {
  header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    h1 {
      position: relative;
      font-size: 30px;
      // color: var(--color-white);
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;

      @include desktop {
        font-size: 50px;
      }
    }
  }

  main {
    section {
      padding: 50px 30px 0;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;

      @include tablet {
        text-align: center;
      }

      @include desktop {
        padding: 100px 0 0;
      }

      &:last-child {
        padding: 50px 30px;

        @include desktop {
          padding: 80px 0;
        }
      }

      h2 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 800;
        color: var(--color-primary);
        letter-spacing: 1.3px;
        margin-bottom: 10px;

        @include desktop {
          font-size: 18px;
        }
      }

      h3 {
        font-size: 24px;
        font-family: var(--font-secondary);
        font-weight: 400;
        line-height: 38px;
      }

      p {
        font-size: 14px;
        line-height: 22px;

        @include desktop {
          font-size: 18px;
          line-height: 28px;
        }
      }

      img {
        display: flex;
      }
    }
    .textBlockFirst {
      h3 {
        font-size: 18px;
        line-height: 26px;
        margin: 30px 0;

        @include desktop {
          margin: 45px 0;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .images {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;

      img {
        object-fit: cover;
        width: 30%;
        height: 300px;

        @include mobile {
          &:first-child,
          &:last-child {
            display: none;
          }
        }

        &:nth-child(2) {
          width: 100%;
          height: 300px;
          object-position: 100% 15%;
          display: block;

          @include desktop {
            width: 40%;
            height: 400px;
          }
        }
      }
    }

    .textBlockSecond {
      width: 100%;
      max-width: 900px;
    }
  }
}
