@import '../assets/scss/mixins.scss';

:root {
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Playfair Display', serif;
  --color-primary: #850403;
  --color-secondary: #d5d5d5;
  --color-tertiary: #ae8570;
  --color-black: #121212;
  --color-white: #fff;
  --color-grey: #555151;
}

html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: var(--color-black);
}

a {
  text-decoration: none;
  color: inherit;
}

.App {
  position: relative;
  min-height: calc(100vh - 80px);
  overflow-x: hidden;
}

.container {
  padding: 80px 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.activeLink {
  opacity: 0.8;
  transition: all 0.2s ease;

  &::after {
    position: absolute;
    content: '';
    top: 58%;
    left: 50%;
    width: 125%;
    height: 3px;
    background: var(--color-primary);
    transition: all 0.5s ease 0.3s;
    transform: translate(-50%, -50%);

    @include tablet {
      width: 110%;
      top: unset;
      bottom: -10px;
      left: 52%;
    }
  }
}

.activeGalleryLink {
  color: var(--color-primary);
  font-weight: 500;
  // background: var(--color-black);

  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: var(--color-primary);
  }
}

.trustoo-widget {
  transform: scale(0.6);
}

.overflow {
  height: 100vh;
  overflow: hidden;
}

.mobile {
  @include desktop {
    display: none;
  }
}

.desktop {
  @include mobile {
    display: none;
  }
}
